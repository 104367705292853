import React from 'react'
import Seo from '../components/molecules/seo'
import Layout from '../components/molecules/layout'

class LostPage extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo title="404 Not Fouund" />
        <section className="section">
          <div className="container">
            <div className="columns">
              <div className="column is-full">
                <main>
                  <h1 className="title lost-title">
                    sorry, we didn't catch that
                  </h1>
                  <iframe
                    src="https://giphy.com/embed/rU8CbsFhssgXFQdvoW"
                    width="480"
                    height="268"
                    frameBorder="0"
                    className="giphy-embed"
                    title="dropping the ball"
                    allowFullScreen
                  ></iframe>
                  <p>
                    <a href="https://giphy.com/gifs/Jomboy-error-mookie-betts-rU8CbsFhssgXFQdvoW">
                      via GIPHY
                    </a>
                  </p>
                </main>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export default LostPage
